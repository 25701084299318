.process-stages {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .stages-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    position: relative;
    align-items: baseline;
  }
  
  .stage-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .stage-dot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .stage-dot {
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
    z-index: 2;
    position: relative;
  }
  
  .stage-dot.active {
    background-color: #4caf50; /* Active stage color */
  }
  
  .stage-line {
    height: 2px;
    background-color: #ccc;
    flex-grow: 1;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    z-index: 1;
  }
  
  .stage-line.active {
    background-color: #4caf50; /* Active line color */
  }
  
  .stage-name {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    width: 80px;
  }
  