.input-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  .input-label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s ease all;
    pointer-events: none;
    color: #aaa;
    text-transform: uppercase;
  }
  
  .shrink {
    top: 15px;
    font-size: 12px;
    color: grey;
  }
  
  .input-field {
    flex: 1;
    border: none;
    outline: none;
    padding: 5px;
    font-size: 16px;
  }
  