body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  background-image: url('https://images.squarespace-cdn.com/content/66056d8f9fc83f3150d325be/edb96da8-4308-4943-9d58-7fe4715f7447/HILI.png');
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
}